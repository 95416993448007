import React, { ReactElement } from 'react'
import { useGetMeQuery } from 'gen/graphql-client-api'

type ContainerProps = {
  renderLoading: () => ReactElement
  renderWithAuth: () => ReactElement
  renderWithoutAuth: () => ReactElement
}

type Props = {
  hasAuth: boolean
  renderWithAuth: () => ReactElement
  renderWithoutAuth: () => ReactElement
}

const Component: React.FC<Props> = ({ hasAuth, renderWithAuth, renderWithoutAuth }) =>
  hasAuth ? renderWithAuth() : renderWithoutAuth()

const Container: React.FC<ContainerProps> = ({ renderLoading, ...rest }) => {
  const [{ data, fetching }] = useGetMeQuery({
    requestPolicy: 'cache-first',
  })
  const hasAuth = !!data?.me

  if (fetching) return renderLoading()

  return <Component {...rest} hasAuth={hasAuth} />
}

export default Container
