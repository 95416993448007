import React from 'react'
import { Heading, Box, Stack, Text } from '@chakra-ui/react'
import { PageWrapper, PageContainer, Header } from 'components/common'
import Navigator from './Navigator'

const Component = () => (
  <>
    <Header subject="404 Not Found" />
    <PageContainer>
      <PageWrapper>
        <Stack
          textAlign="center"
          height="100%"
          width="100%"
          spacing={4}
          justifyContent="center"
          px={3}
        >
          <Box>
            <Heading size="lg" mb={4}>
              404 Not Found
            </Heading>
            <Text>ページが見つかりません</Text>
          </Box>
          <Box mt={12}>
            <Navigator />
          </Box>
        </Stack>
      </PageWrapper>
    </PageContainer>
  </>
)

export default Component
