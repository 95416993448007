import React from 'react'
import Link from 'next/link'
import { Button } from 'components/common'
import Session from 'utils/Session'

const Component = () => (
  <Session
    renderLoading={() => (
      <Button isLoading w="full">
        Loading...
      </Button>
    )}
    renderWithAuth={() => (
      <Link href="/mypage">
        <Button colorScheme="primary" w="full">
          マイページはこちら
        </Button>
      </Link>
    )}
    renderWithoutAuth={() => (
      <Link href="/signin">
        <Button colorScheme="primary" w="full">
          ログインはこちら
        </Button>
      </Link>
    )}
  />
)

export default Component
